export const LotoNumbers = [
    {"value": "1", "name": "un"},
    {"value": "2", "name": "deux"},
    {"value": "3", "name": "trois"},
    {"value": "4", "name": "quatre"},
    {"value": "5", "name": "cinq"},
    {"value": "6", "name": "six"},
    {"value": "7", "name": "sept"},
    {"value": "8", "name": "huit"},
    {"value": "9", "name": "neuf"},
    {"value": "10", "name": "dix"},
    {"value": "11", "name": "onze"},
    {"value": "12", "name": "douze"},
    {"value": "13", "name": "treize"},
    {"value": "14", "name": "quatorze"},
    {"value": "15", "name": "quinze"},
    {"value": "16", "name": "seize"},
    {"value": "17", "name": "dix-sept"},
    {"value": "18", "name": "dix-huit"},
    {"value": "19", "name": "dix-neuf"},
    {"value": "20", "name": "vingt"},
    {"value": "21", "name": "vingt-et-un"},
    {"value": "22", "name": "vingt-deux"},
    {"value": "23", "name": "vingt-trois"},
    {"value": "24", "name": "vingt-quatre"},
    {"value": "25", "name": "vingt-cinq"},
    {"value": "26", "name": "vingt-six"},
    {"value": "27", "name": "vingt-sept"},
    {"value": "28", "name": "vingt-huit"},
    {"value": "29", "name": "vingt-neuf"},
    {"value": "30", "name": "trente"},
    {"value": "31", "name": "trente-et-un"},
    {"value": "32", "name": "trente-deux"},
    {"value": "33", "name": "trente-trois"},
    {"value": "34", "name": "trente-quatre"},
    {"value": "35", "name": "trente-cinq"},
    {"value": "36", "name": "trente-six"},
    {"value": "37", "name": "trente-sept"},
    {"value": "38", "name": "trente-huit"},
    {"value": "39", "name": "trente-neuf"},
    {"value": "40", "name": "quarante"},
    {"value": "41", "name": "quarante-et-un"},
    {"value": "42", "name": "quarante-deux"},
    {"value": "43", "name": "quarante-trois"},
    {"value": "44", "name": "quarante-quatre"},
    {"value": "45", "name": "quarante-cinq"},
    {"value": "46", "name": "quarante-six"},
    {"value": "47", "name": "quarante-sept"},
    {"value": "48", "name": "quarante-huit"},
    {"value": "49", "name": "quarante-neuf"},
    {"value": "50", "name": "cinquante"},
    {"value": "51", "name": "cinquante-et-un"},
    {"value": "52", "name": "cinquante-deux"},
    {"value": "53", "name": "cinquante-trois"},
    {"value": "54", "name": "cinquante-quatre"},
    {"value": "55", "name": "cinquante-cinq"},
    {"value": "56", "name": "cinquante-six"},
    {"value": "57", "name": "cinquante-sept"},
    {"value": "58", "name": "cinquante-huit"},
    {"value": "59", "name": "cinquante-neuf"},
    {"value": "60", "name": "soixante"},
    {"value": "61", "name": "soixante-et-un"},
    {"value": "62", "name": "soixante-deux"},
    {"value": "63", "name": "soixante-trois"},
    {"value": "64", "name": "soixante-quatre"},
    {"value": "65", "name": "soixante-cinq"},
    {"value": "66", "name": "soixante-six"},
    {"value": "67", "name": "soixante-sept"},
    {"value": "68", "name": "soixante-huit"},
    {"value": "69", "name": "soixante-neuf"},
    {"value": "70", "name": "soixante-dix"},
    {"value": "71", "name": "soixante-et-onze"},
    {"value": "72", "name": "soixante-douze"},
    {"value": "73", "name": "soixante-treize"},
    {"value": "74", "name": "soixante-quatorze"},
    {"value": "75", "name": "soixante-quinze"},
    {"value": "76", "name": "soixante-seize"},
    {"value": "77", "name": "soixante-dix-sept"},
    {"value": "78", "name": "soixante-dix-huit"},
    {"value": "79", "name": "soixante-dix-neuf"},
    {"value": "80", "name": "quatre-vingts"},
    {"value": "81", "name": "quatre-vingt-un"},
    {"value": "82", "name": "quatre-vingt-deux"},
    {"value": "83", "name": "quatre-vingt-trois"},
    {"value": "84", "name": "quatre-vingt-quatre"},
    {"value": "85", "name": "quatre-vingt-cinq"},
    {"value": "86", "name": "quatre-vingt-six"},
    {"value": "87", "name": "quatre-vingt-sept"},
    {"value": "88", "name": "quatre-vingt-huit"},
    {"value": "89", "name": "quatre-vingt-neuf"},
    {"value": "90", "name": "quatre-vingt-dix"}
];
